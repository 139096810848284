<template>
  <div class="p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold">Add Customer Type</h1>
      </div>
      <form @submit.prevent="submitCustomerType" class="max-w-3xl space-y-5">
        <div class="text-lg space-y-5">
          <div>
            <label
              for="customertype_name"
              class="block text-sm font-medium text-gray-600"
            >
              Name
            </label>
            <div class="mt-1">
              <input
                v-model="customertype.customertype_name"
                required
                maxlength="50"
                name="customertype_name"
                class="w-full border rounded px-4 py-2"
              />
            </div>
          </div>
        </div>

        <div class="pt-2 flex justify-center">
          <button
            type="submit"
            class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
          >
            <div v-if="isPending" class="spinner"></div>
            Save
          </button>
        </div>

        <div v-if="error" class="text-center text-red-600 font-semibold">
          {{ error }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebaseConfig";

export default {
  props: ["id", "user"],
  setup(props) {
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const business_id = props.user.claims.business_id ?? props.id;
    const customertype = ref({});
    const isPending = ref(false);

    const { error, addDoc } = useCollection(
      `businesses/${business_id}/customer_types`
    );

    const goTo = (url) => {
      router.push(url);
    };

    const submitCustomerType = async () => {
      isPending.value = true;
      await addDoc({
        createdAt: timestamp(),
        createdBy: props.user.uid,
        customertype_name: customertype.value.customertype_name ?? "",
      });
      isPending.value = false;
      if (!error.value) {
        router.push(route.path.substring(0, route.path.lastIndexOf("/")));
      }
    };

    return {
      isLoading,
      submitCustomerType,
      route,
      isPending,
      error,
      customertype,
      goTo,
      router,
    };
  },
};
</script>
